import _slicedToArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useResolveAddressLazyQuery } from '@strumento/models';
import { useEffect, useMemo } from 'react';
export function useAddressFromPostcodeAndNumber(postcode, number) {
  var _useResolveAddressLaz = useResolveAddressLazyQuery(),
      _useResolveAddressLaz2 = _slicedToArray(_useResolveAddressLaz, 2),
      getAddressQuery = _useResolveAddressLaz2[0],
      addressResponse = _useResolveAddressLaz2[1].data;

  useEffect(function () {
    if (isNaN(number) || !(postcode !== null && postcode !== void 0 && postcode.trim().match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i))) {
      return;
    }

    getAddressQuery({
      variables: {
        number: number,
        postcode: postcode
      }
    });
  }, [postcode, number, getAddressQuery]);
  var address = useMemo(function () {
    var _addressResponse$reso;

    return (_addressResponse$reso = addressResponse === null || addressResponse === void 0 ? void 0 : addressResponse.resolvePostcode) !== null && _addressResponse$reso !== void 0 ? _addressResponse$reso : null;
  }, [postcode, number]);
  return address;
}