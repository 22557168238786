import { parse, format } from 'date-fns';
import nl from 'date-fns/locale/nl';
export function parseDate(input) {
  return parse(input, 'dd-MM-yyyy', new Date());
}
export function formatDate(date) {
  return format(date, 'PPP', {
    locale: nl
  });
}
export function formatDateFromIso(date) {
  return format(new Date(date), 'PPP', {
    locale: nl
  });
}